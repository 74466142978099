import React, { useEffect } from "react";
import slugify from "slugify";
import { graphql } from "gatsby";
// import Custom Components
import Breadcrumb from "../components/common/breadcrumb";
import MediaOne from "./partials/media/media-one";
import DetailOne from "./partials/details/detail-one";
import Seo from "../components/common/SEO";
import MainLayout from "../layouts/MainLayout";
import { productGallery } from "../utils";

function Product(props) {
  /* if product pickup storelocation are to be used {product, storeLocations}*/
  const { product, metadata, klarna_img } = props.data;
  const { location } = props;
  const { storeMetadata } = props.pageContext;

  useEffect(() => {
    let getItems = JSON.parse(localStorage.getItem("recentItems")) || [];

    if (!getItems.includes(product._id)) {
      getItems.push(product._id);
    }
    if (getItems.length > 5) {
      getItems.shift();
    }
    localStorage.setItem("recentItems", JSON.stringify(getItems));
    // return () => {
    //   cleanup
    // }
  }, [product._id]);
  useEffect(() => {
    productGallery();

    document.querySelector(".skel-pro-single").classList.add("loaded");
  }, [product]);

  const category = product.category;
console.log(product)
  return (
    <>
      <MainLayout>
        <Seo title={product.title} />

        <div className="main">
          <Breadcrumb
            title={product.title}
            // slug="extended"
            parent1={[
              "allcollection",
              "collections/" + slugify(category?.name.toLowerCase()),
            ]}
            // type="product"
            adClass="breadcrumb-nav border-0 mb-0"
            // productId={ product._id }
          />

          <div className="page-content">
            <div className="container">
              <div className="product-details-top mb-2 skeleton-body horizontal">
                <div className="row skel-pro-single">
                  <div className="col-md-6">
                    <MediaOne product={product} adClass="" />
                  </div>

                  <div className="col-md-6">
                    <div className="entry-summary row">
                      <div className="col-md-12">
                        <div className="entry-summary1"></div>
                      </div>

                      <div className="col-md-12">
                        <div className="entry-summary2"></div>
                      </div>
                    </div>
                    {/* if product pickup storelocation are to be pass prop contact={storeLocations}*/}
                    <DetailOne
                      location={location}
                      storeMetadata={storeMetadata}
                      product={product}
                      metadata={metadata}
                      klarna_banner={klarna_img}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <DescTwo id={ product._id } /> */}

            {/* {(category_subCategory.length > 0 ||
              category_products.length > 0) && (
              <div className="container">
                <h2 className="title text-center mb-4">You May Also Like</h2>

                <div className="row">
                  <div
                    className="col"
                    className={
                      count >= 4
                        ? "col"
                        : count >= 3
                        ? "col-7"
                        : count >= 2
                        ? " col-lg-4 col-xs-12 col-sm-12  "
                        : "col-xl-3 col-lg-12 col-md-12 col-xs-12  col-sm-12 "
                    }
                  >
                    <Slider
                      {...sliderProps}
                      slidesToShow={count > 6 ? 6 : count}
                      className=""
                    >
                      {category_products && category_products.length > 0
                        ? category_products.map((item, index) => (
                            <ProductNine
                              product={item}
                              key={index}
                              onAddToCart={addToCart}
                              onToggleWishlist={toggleWishlist}
                              onAddToCompare={addToCompare}
                              showQuickView={showQuickViewModal}
                            />
                          ))
                        : category_subCategory.map((item, index) => (
                            <ProductNine
                              product={item}
                              key={index}
                              onAddToCart={addToCart}
                              onToggleWishlist={toggleWishlist}
                              onAddToCompare={addToCompare}
                              showQuickView={showQuickViewModal}
                            />
                          ))}
                    </Slider>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </MainLayout>
    </>
  );
}

/*===if product pickup options are to be shown 
 storeLocations(isDefault: {eq: true}) {
      name
      address
      state
      city
      zipCode
      
    }
    
*/
export const query = graphql`
  query getCategories($id: String) {
    metadata{
      stripeAccount
      paymentMethods
      ratingsVisibility
    }
    klarna_img: file(relativePath: { eq: "klarna_branding.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 450, quality: 100, layout: CONSTRAINED)
      }
    }
    product: products(id: { eq: $id }) {
      _id
      cl_upc
      title
      description
      quantity
      price
      oldPrice
      brand
      isDefaultShippingEnabled
      isDeliverable

      isPickup
      isShippable
      rating
      condition
      category {
        _id
        name
      }
      seoMetaData {
        title
        description
        slug
      }
      subCategory
      aspects {
        Types
        Color
        Weight
        Dimensions
        Model
        Width
        Length
        Height
        Part_Number
        Item_Weight
        Product_Dimensions
        Installation_Method
        Item_Package_Quantity
        Thickness
        Square_Feet_per_Box
      }
      storeLocation {
        _id
        name
        state
        zipCode
        address
      }
      images {
        childImageSharp {
          gatsbyImageData( layout: CONSTRAINED)
        }
      }
      thumbnail {
        childImageSharp {
          gatsbyImageData( quality: 100, layout: CONSTRAINED)
        }
      }
    }
  }
`;
export default Product;
