import {
  AffirmMessageElement,
  AfterpayClearpayMessageElement,
  Elements,
} from "@stripe/react-stripe-js"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import {getStripe} from "../../../utils"

function StripeBanners({ accountId, paymentMethods, price, klarna_img }) {
  const stripe = getStripe(accountId)
  const [aprice,setPrice]=useState(price)
  
  return (
    <>
      {paymentMethods.includes("affirm") && Number(aprice) >= 50 && (
        <Elements stripe={stripe}>
          <AffirmMessageElement
            options={{ amount: Number(aprice) * 100, currency: "USD" }}
          />
        </Elements>
      )}
      {paymentMethods?.includes("afterpay_clearpay_payments") && (
          <Elements stripe={stripe}>
            <AfterpayClearpayMessageElement
              options={{ amount: Number(aprice) * 100, currency: "USD" }}
            />
          </Elements>
        )}
      {paymentMethods?.includes("klarna") && (
        <GatsbyImage
          image={klarna_img?.childImageSharp?.gatsbyImageData}
          className="img-fluid"
          alt=""
        />
      )}
    </>
  )
}

export default StripeBanners
